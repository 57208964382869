import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
    const { t } = useTranslation();
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow: "1px 2px 9px rgb(0 0 0 / 0.2)",
                    padding: "50px",
                    borderRadius: "10px",
                }}
            >
                <img
                    src="/assets/images/logo.png"
                    alt="logo"
                    style={{ width: "120px", marginBottom: "45px" }}
                />
                <Typography component="h1" align="center">
                    <ErrorOutlineIcon fontSize="large" color="error" />
                </Typography>
                <Box sx={{ mt: 3 }} className="form-input">
                    <Typography component="h1" variant="h6" align="center">
                        Oops. {t("Something went wrong !")} <br />
                        {t(
                            "Please close this webview and try opening it again."
                        )}
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default ErrorPage;
