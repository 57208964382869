import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ArrowUpward } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import IdCheckIo from "idcheckio-sdk";
import { sendLink } from "../../api";
import { useTranslation } from "react-i18next";

const ValidationPage = () => {
    const [endOnboarding, setEndOnboarding] = useState(false);
    const { t } = useTranslation();

    const navigate = useNavigate();
    const idcheckRef = useRef(null);

    const idcheck = new IdCheckIo({
        forceMobileLayout: true,
        platform: "https://sdkweb.idcheck.ariadnext.io/v2",
    });

    const onEndOnboarding = () => {
        setEndOnboarding(true);
    };

    idcheck.on("NAVIGATION_END", (event) => onEndOnboarding());
    idcheck.on("ENDED", (event) => onEndOnboarding());

    useEffect(() => {
        const getResourceUid = async () => {
            const response = await sendLink();
            idcheck.init({
                uid: response.data.resource_id,
                element: idcheckRef.current,
            });
        };
        getResourceUid();
    }, []);

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow: "1px 2px 9px rgb(0 0 0 / 0.2)",
                    padding: "10px",
                    borderRadius: "10px",
                }}
                style={{ height: "95%" }}
            >
                {!endOnboarding ? (
                    <IconButton
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => {
                            navigate("/validation");
                        }}
                        className="go-back-button"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                ) : (
                    <ArrowUpward className="leave-button" />
                )}
                {!endOnboarding ? (
                    <Box
                        sx={{ mt: 3 }}
                        style={{ height: "100%", width: "100%" }}
                    >
                        <div
                            ref={idcheckRef}
                            style={{ height: "100%", width: "100%" }}
                        >
                            Loading...
                        </div>
                    </Box>
                ) : (
                    <Box
                        sx={{ mt: 3 }}
                        style={{ height: "100%", width: "100%" }}
                    >
                        <Typography component="h1" variant="h6" align="center">
                            {t(
                                "Thank you for submitting your documents. Please close this page, we'll get back to you shortly."
                            )}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default ValidationPage;
