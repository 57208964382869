import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { usePlacesWidget } from "react-google-autocomplete";
import LinearProgress from "@mui/material/LinearProgress";
import { postAddress } from "../../api";
import { useTranslation } from "react-i18next";

const AddressPage = ({ username }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const { t } = useTranslation();
    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        onPlaceSelected: async (place) => {
            if (place.address_components === undefined) return;

            setIsLoading(true);
            let response = await postAddress(place);
            if (!response.error) navigate("/document");
        },
        options: {
            types: ["address"],
            strictBounds: false,
        },
    });
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow: "1px 2px 9px rgb(0 0 0 / 0.2)",
                    padding: "50px",
                    borderRadius: "10px",
                }}
            >
                <img
                    src="/assets/images/logo.png"
                    alt="logo"
                    style={{ width: "120px", marginBottom: "45px" }}
                />
                <Typography component="h1" variant="subtitle1">
                    <center>
                        {t("Hey")}, {username}.
                    </center>
                </Typography>
                <Typography component="h1" variant="subtitle2">
                    {t("Enter your postal address")}
                </Typography>
                <Box sx={{ mt: 3 }} className="form-input">
                    {isLoading ? (
                        <LinearProgress />
                    ) : (
                        <>
                            <input
                                ref={ref}
                                margin="normal"
                                required
                                id="address"
                                label="Address"
                                name="address"
                                autoFocus
                                className="input"
                                autoComplete="off"
                                type="text"
                            />
                            <label htmlFor="address">
                                {t("Enter your postal address")}
                            </label>
                        </>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default AddressPage;
