import axios from "axios";

const resolve = async (promise) => {
    const resolved = {
        data: null,
        error: null,
    };

    try {
        resolved.data = await promise;
    } catch (e) {
        resolved.error = e;
    }

    return resolved;
};

export const postAddress = async (data) => {
    let endpoint = `api/id_validation/address/?jwt_token=${localStorage.getItem(
        "jwt_token"
    )}`;
    let absolute_url = `${process.env.REACT_APP_API_URL}${endpoint}`;

    return await resolve(
        axios.post(absolute_url, { data }).then((res) => res.data)
    );
};

export const postDocumentCountry = async (iso_code) => {
    let endpoint = `api/id_validation/document-country/?jwt_token=${localStorage.getItem(
        "jwt_token"
    )}`;
    let absolute_url = `${process.env.REACT_APP_API_URL}${endpoint}`;

    return await resolve(
        axios.post(absolute_url, { iso_code }).then((res) => res.data)
    );
};

export const sendLink = async () => {
    let endpoint = `api/id_validation/sendlink/?jwt_token=${localStorage.getItem(
        "jwt_token"
    )}`;
    let absolute_url = `${process.env.REACT_APP_API_URL}${endpoint}`;

    return await resolve(axios.post(absolute_url).then((res) => res.data));
};
