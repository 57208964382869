import { useEffect, useState } from "react";
import AddressPage from "./pages/Address";
import ValidationPage from "./pages/Validation";
import ErrorPage from "./pages/Error";
import DocumentCountryPage from "./pages/DocumentCountry";

import {
    Routes,
    Route,
    useLocation,
    useSearchParams,
    useNavigate,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jwt from "jwt-decode";
import { Suspense } from "react";

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const theme = createTheme();
    const [username, setUsername] = useState("");

    useEffect(() => {
        let jwt_token = searchParams.get("jwt_token");
        if (!jwt_token) jwt_token = localStorage.getItem("jwt_token");

        if (!jwt_token) {
            navigate("/error", { replace: true });
            return;
        }

        let jwt_token_data;
        try {
            jwt_token_data = jwt(jwt_token);
            if (Date.now() < jwt_token_data.exp)
                throw new Error("Unfortunately, your JWT is expired");
        } catch (error) {
            console.log(error);
            navigate("/error", { replace: true });
            return;
        }
        localStorage.setItem("jwt_token", jwt_token);
        localStorage.setItem("username", jwt_token_data.username);
        setUsername(jwt_token_data.username);
    }, [navigate, searchParams]);

    return (
        <Suspense fallback="...loading">
            <TransitionGroup component={null}>
                <CSSTransition
                    key={location.key}
                    classNames="fade"
                    timeout={300}
                >
                    <ThemeProvider theme={theme}>
                        <Routes location={location}>
                            <Route
                                path="/"
                                element={<AddressPage username={username} />}
                            />
                            <Route
                                path="document"
                                element={<DocumentCountryPage />}
                            />
                            <Route
                                path="/validation"
                                element={<ValidationPage />}
                            />
                            <Route path="/error" element={<ErrorPage />} />
                        </Routes>
                    </ThemeProvider>
                </CSSTransition>
            </TransitionGroup>
        </Suspense>
    );
};

export default App;
