import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";
import Select from "react-select";
import { postDocumentCountry } from "../../api";
import { useNavigate } from "react-router-dom";

const DocumentCountryPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const documentCountry = undefined;

    const options = React.useMemo(() => countryList().getData(), []);

    const onCountrySelect = async (country) => {
        let response = await postDocumentCountry(country.value);
        if (!response.error) navigate("/validation");
    };

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow: "1px 2px 9px rgb(0 0 0 / 0.2)",
                    padding: "50px",
                    borderRadius: "10px",
                }}
            >
                <img
                    src="/assets/images/logo.png"
                    alt="logo"
                    style={{ width: "120px", marginBottom: "45px" }}
                />
                <Typography component="h1" variant="subtitle2">
                    {t(
                        "What country issued the document you'll provide us today ?"
                    )}
                </Typography>
                <Box sx={{ mt: 3 }} className="form-input">
                    <Select
                        options={options}
                        value={documentCountry}
                        onChange={onCountrySelect}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: "40px",
                            }),
                            valueContainer: (baseStyles, state) => ({
                                ...baseStyles,
                                height: "40px",
                            }),
                            input: (baseStyles, state) => ({
                                ...baseStyles,
                                height: "40px",
                            }),
                            indicatorsContainer: (baseStyles, state) => ({
                                ...baseStyles,
                                height: "40px",
                            }),
                        }}
                    />
                </Box>
            </Box>
        </Container>
    );
};

export default DocumentCountryPage;
